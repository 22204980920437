<template>
  <div class="portfolio">
    <Menu />
    <button
      v-if="fullscreenSupport"
      @click="toggleFullscreen"
      class="fullscreen-global-button button"
      :title="isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'"
    />
    <router-view v-slot="{ Component }">
      <transition
        appear
        appear-class="no-animation"
        appear-to-class="no-animation"
        appear-active-class="no-animation"
        name="zoom"
        mode="out-in"
        :css="animated"
        v-on:appear="beforeEnter"
        v-on:before-enter="beforeEnter"
        v-on:after-enter="afterEnter"
        v-on:before-leave="beforeLeave"
        v-on:after-leave="afterLeave"
      >
        <component :is="Component" />
      </transition>
    </router-view>
    <Film v-if="isVideoOpen" />
  </div>
</template>

<script>
/* eslint-disable no-undef, no-unused-vars, vue/no-unused-components */
import store from "@/store";
import {
  SET_EXHIBITION,
  //SET_VIDEO_ESCAPE,
  SET_FULLSCREEN_ESCAPE,
  CLEAR_PROJECT,
  OPEN_FULLSCREEN,
  CLOSE_FULLSCREEN,
} from "@/store/handlers";
import { mapState, mapGetters, mapMutations } from "vuex";
import Menu from "@/components/Menu";
import Film from "@/components/Film";

export default {
  components: {
    Menu,
    Film,
  },
  computed: {
    ...mapState(["fullscreenSupport"]),
    ...mapGetters([
      "isFullscreen",
      "isProjectEntered",
      "isProjectOpen",
      "isVideoOpen",
    ]),
    animated() {
      return this.isProjectEntered ? false : true;
    },
  },
  methods: {
    ...mapMutations({
      flag: SET_EXHIBITION,
    }),
    beforeEnter() {
      this.flag("entering");
    },
    afterEnter() {
      this.flag("entered");
    },
    beforeLeave() {
      this.flag("leaving");
    },
    afterLeave() {
      this.flag("left");
      store.dispatch(CLEAR_PROJECT);
    },
    toggleFullscreen() {
      if (this.isFullscreen) {
        store.dispatch(CLOSE_FULLSCREEN);
      } else {
        store.dispatch(OPEN_FULLSCREEN, document.documentElement);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isVideoOpen) {
      store.commit(SET_FULLSCREEN_ESCAPE, true);
      next(false);
    } else next();
  },
};
</script>
